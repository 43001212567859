import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useEffect, useRef } from 'react';
type Props = {
  delegate?: any;
  options?: any;
  children?: any;
  classNameContainer?: string
}

export default function Fancybox(props: Props) {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    // if (container) {
    NativeFancybox.bind(container, delegate, options);
    return () => {
      NativeFancybox.unbind(container);
    };
    // }

    // NativeFancybox.bind(container, delegate, options);
    // return () => {
    //   NativeFancybox.unbind(container);
    //   // NativeFancybox.close();
    // };
  });
  return (
    <div className={props?.classNameContainer} ref={containerRef}>{props.children}</div>
  )
}