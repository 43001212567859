/* eslint-disable @next/next/no-img-element */
import useContentGlobal from '@/hooks/useContentGlobal';
import { Fragment, useEffect, useState } from 'react';
import { PopupNoti } from '../common';
// @ts-ignore
import Minimap from "react-minimap";
import 'react-minimap/dist/react-minimap.css';
import { LoadingCustom } from '../common/LoadingCustom';
import IndicatorScroll from './IndicatorScroll';

export const MovieSeat = ({ listSeat, qtyTicket, setOrdersSeat, ordersSeat, cinemaInterest, isLoading, refElementRef, isExistOrderSeat }: any) => {
  const [noti, setNoti] = useState({ value: false, content: "", })
  const { getValueByKey } = useContentGlobal();
  let currentRow = 0

  const HanleMapShowHide = () => {
    const miniMap = document.querySelector(".minimap");
    if (miniMap?.classList.contains("show")) {
      miniMap?.classList.remove("show");
    } else {
      miniMap?.classList.add("show");
    }
  }
  const HandleMapTouchStart = () => {
    HanleMapShowHide();
  };

  const HandleMapTouchEnd = () => {
    HanleMapShowHide();
  };

  const handleAddWithTypeSeat = (type: string, _this: any, seat: any, rows: any) => {
    let totalSeat = qtyTicket?.reduce((acc: any, item: any) => { return item?.seatstyle_name == type ? acc + item?.qty : acc }, 0)
    const listOrdersSeatCouple = ordersSeat?.filter(((item: any) => item?.seat_type == type))

    // ghế đôi thì khỏi check
    if (type == "ĐÔI") {
      const isChoosing = ordersSeat?.find((val: any) => val?.name == seat?.name)
      if (isChoosing) {
        setOrdersSeat([...ordersSeat.filter((item: any) => item?.ticketid !== seat?.ticketid)])
      } else {
        if (listOrdersSeatCouple?.length >= totalSeat) {
          setNoti({ value: true, content: "Bạn đã mua đủ ghế loại này!" });
        } else {
          setOrdersSeat([...ordersSeat, seat])
          sessionStorage.setItem('timerSeat', new Date().getTime().toString())
        }
      }
      return
    }

    // bắt đầu logic check ghế đơn
    // const qtySeatRemaining = totalSeat - ordersSeat?.filter((item: any) => item?.seat_type == type)?.length - (_this.classList.contains("choosing") ? -1 : 1)
    const setNotiError = () => {
      setNoti({ value: true, content: getValueByKey(["Message", "Error", "Select_Seat_2"]) ?? "Việc chọn ghế của bạn không được để trống <span class='c-second'>2 ghế ở bên trái, giữa</span> hoặc <span class='c-second'>bên phải trên cùng</span> một hàng ghế mà bạn vừa chọn!" });
    }
    const setNotiError1 = () => {
      setNoti({ value: true, content: getValueByKey(["Message", "Error", "Select_Seat_1"]) ?? "Việc chọn ghế của bạn không được để trống 1 ghế ở bên trái, giữa hoặc bên phải trên cùng một hàng ghế mà bạn vừa chọn!" });
    }
  
    
    const rowsNotContainCorridor = rows.filter((item: any) => item.isseat)
    const isSeatInvalid = (seat: any) => seat == null || seat == undefined;

    const indexSeat = rows?.findIndex((item: any) => item?.name == seat?.name)
    const isCheckLeft = ordersSeat?.find((val: any) => val?.name == rows[indexSeat - 1]?.name)
    const isCheckRight = ordersSeat?.find((val: any) => val?.name == rows[indexSeat + 1]?.name)
    const isCheckLeft2 = ordersSeat?.find((val: any) => val?.name == rows[indexSeat - 2]?.name)
    const isCheckRightt2 = ordersSeat?.find((val: any) => val?.name == rows[indexSeat + 2]?.name)
    const isSoldOnLeft = rows[indexSeat - 1]?.issold
    const isSoldonRight = rows[indexSeat + 1]?.issold
    const isSoldOnLeft2 = rows[indexSeat - 2]?.issold
    const isSoldonRight2 = rows[indexSeat + 2]?.issold
    const isSoldOnLeft3 = rows[indexSeat - 3]?.issold
    const isSoldonRight3 = rows[indexSeat + 3]?.issold

    const isLeft = (!isSoldOnLeft && isSoldOnLeft2 && !isCheckLeft && (!isCheckRight || isSoldOnLeft2)) || (isCheckLeft2 && !isSoldOnLeft && !isCheckLeft)
    const isRight = (!isSoldonRight && isSoldonRight2 && !isCheckRight && (!isCheckLeft || isSoldonRight2)) || (isCheckRightt2 && !isSoldonRight && !isCheckRight)
    const isDouble = ((isSoldOnLeft == null || isSoldOnLeft == true) && isSoldonRight == false) || ((isSoldonRight == null || isSoldonRight == true) && isSoldOnLeft == false)

    const seatBiKep = (isSoldOnLeft && isSoldonRight2) || (isSoldonRight && isSoldOnLeft2) || (isSeatInvalid(isSoldOnLeft) && isSeatInvalid(isSoldonRight2)) || (isSeatInvalid(isSoldonRight) && isSeatInvalid(isSoldOnLeft2))

    if (_this.classList.contains("choosing")) {
      if ((isSoldOnLeft && isCheckRight && !isSoldonRight3) || (isSoldonRight && isCheckLeft && !isSoldOnLeft3)) {
        console.log('báo lỗi 11 ', isSoldOnLeft3, (isSoldOnLeft && isCheckRight && !isSoldonRight3))
        return setNotiError()
      }
      if (type == "ĐƠN" && (isCheckLeft) && (isCheckRight)) {
        console.log('báo lỗi 11.1')
        return setNoti({ value: true, content: getValueByKey(["Message", "Error", "Select_Seat_1"]) ?? "Việc chọn ghế của bạn không được để trống 1 ghế ở bên trái, giữa hoặc bên phải trên cùng một hàng ghế mà bạn vừa chọn!" });
      }
      if (((isCheckLeft && (isSoldonRight || isSeatInvalid(isSoldonRight)) && !isSoldOnLeft2 && !isSoldOnLeft3) || (isCheckRight && (isSoldOnLeft || isSeatInvalid(isSoldOnLeft)) && !isSoldonRight2 && !isSoldonRight3)) && listOrdersSeatCouple?.length < 2) {
        console.log('báo lỗi 11.2')
        return setNotiError1()
      }
      if (type == 'ĐƠN' && !(isCheckLeft && isCheckRight)) {
        const newArr = ordersSeat?.filter((item: any) => item?.ticketid !== seat?.ticketid) || []
        return setOrdersSeat(newArr)
      }


    } else {
      
      const isCheckNear = isCheckLeft || isCheckRight
      const isSoldonNear = isSoldOnLeft || isSoldonRight
      //check hàng đó đã có ghế nào được chọn chưa
      // const isExistSeat = rows?.find((item: any) => item?.issold ==true)
      // console.log('isExistSeat', isExistSeat, getValueByKey(["Message", "Error", "Select_Seat_1"]))
      // // chỉ cho chọn ghế kế bên ghế đã bán hoặc ghế đã chọn
      // if ((!isCheckNear && !isSoldonNear) && !seatBiKep && isExistSeat) { 
      //   console.log('báo lỗi 12')
      //   return setNotiError()
      // }
      // chọn ghế kế bến ghế đã chọn hoặc ghế đã bán 
      if ((isCheckNear || isSoldonNear) && !seatBiKep ) {
        if (!isCheckLeft && isSoldOnLeft2 && !isSoldOnLeft && !(isSoldOnLeft2 && isSoldonRight2 && (isCheckRight || isCheckLeft)) && !(!!isCheckRight && !!isCheckRightt2)) {
          console.log('báo lỗi 12.1')
          return setNotiError()
        }
        if (!isCheckRight && isSoldonRight2 && !isSoldonRight && !(isSoldOnLeft2 && isSoldonRight2 && (isCheckRight || isCheckLeft)) && !((!!isCheckLeft && !!isCheckLeft2))) {
          console.log('báo lỗi 12.2')
          return setNotiError()
        }
        if (isSoldOnLeft && !isSoldonRight && isCheckRightt2 && !isCheckRight && !isCheckRightt2) {
          console.log('báo lỗi 12.3')
          return setNotiError()
        }
        if (isSoldonRight && !isSoldOnLeft && isCheckLeft2 && !isCheckLeft) {
          console.log('báo lỗi 12.4')
          return setNotiError()
        }
      }
      // check điều kiện chọn ghế ở rìa và kề lối đi
      if (isSeatInvalid(isSoldOnLeft) && isSoldonRight3 && !isCheckRight && !isSoldonRight) {
        console.log('báo lỗi 12.5')
        return setNotiError()
      }
      if (isSeatInvalid(isSoldonRight) && isSoldOnLeft3 && !isCheckLeft && !isSoldOnLeft) {
        console.log('báo lỗi 12.6')
        return setNotiError()
      }


      if (!isCheckLeft && !isCheckRight) {
        //chọn ghế ở kề rìa và kề lối đi bên phải
        if (!isSoldonRight) {
          if (isSoldonRight !== isSoldonRight2 && isSoldonRight !== null && !isSeatInvalid(isSoldOnLeft) && !isSeatInvalid(isSoldOnLeft2) && !isSoldOnLeft) {
            console.log('báo lỗi 12.8')
            return setNotiError()
          }
        }

        // chọn ghế ở kề rìa và kề lối đi bên trái
        if (!isSoldOnLeft) {
          if (isSoldOnLeft !== isSoldOnLeft2 && isSoldOnLeft !== null && !isSeatInvalid(isSoldonRight) && !isSeatInvalid(isSoldonRight2) && !isSoldonRight) {
            console.log('báo lỗi 12.9')
            return setNotiError()
          }
        }
      }

      // chọn ghế mà bỏ ghế ở giữa
      if ((!isCheckLeft && isCheckLeft2) || (!isCheckRight && isCheckRightt2)) {
        return setNotiError()
      }

      // ghế thứ 2 và ghế kề cuối
      if (type == "ĐƠN" && ((rowsNotContainCorridor?.findIndex((item: any) => item?.name == seat?.name) == 1 && !isCheckLeft && !isCheckRight && !isSoldOnLeft && !isSoldonRight && isSoldonRight !== null) || (rowsNotContainCorridor?.findIndex((item: any) => item?.name == seat?.name) == rowsNotContainCorridor?.length - 2 && !isCheckRight && !isCheckLeft && !isSoldOnLeft && !isSoldonRight && isSoldOnLeft !== null))) {
        console.log('báo lỗi 0')
        return setNotiError()
      }

      // check chọn ghế mà bỏ ghế ở giữa
      if (type == 'ĐƠN' && ((!isCheckLeft && !isSoldOnLeft && isCheckLeft2) || (!isCheckRight && !isSoldonRight && isCheckRightt2))) {
        console.log('báo lỗi 1')
        return setNotiError()
      }

      // check chọn ghế mà bỏ ghế ở giữa ghế đã bán
      if (type == "ĐƠN" && (isLeft || isRight) && !isDouble && !isSoldOnLeft2 && !isSoldonRight2) {
        console.log('báo lỗi2')
        setNotiError()
      } else {

        if (type == "ĐƠN" && isDouble && (!!isCheckLeft2 && !!isCheckRightt2)) {
          console.log('báo lỗi3')
          return setNotiError()
        }
        if (totalSeat == 0 && !_this.classList.contains("choosing")) {
          //chưa set số lượng ghế
          setNoti({ value: true, content: getValueByKey(["Message", "Error", "Empty_Seat"]) ?? "Bạn không có mua ghế loại này!" });
        } else {
          if (listOrdersSeatCouple?.length >= totalSeat) {
            setNoti({ value: true, content: "Bạn đã mua đủ ghế loại này!" });
          } else {
            setOrdersSeat([...ordersSeat, seat])
            sessionStorage.setItem('timerSeat', new Date().getTime().toString())
          }
        }
      }
    }
  }

  useEffect(() => {
    if (ordersSeat?.length > 0) {
      sessionStorage.setItem('timerSeat', (new Date().getTime() + 5 * 60 * 1000).toString())
      window.dispatchEvent(new Event('timerSeat'));
    }
  }, [ordersSeat])

  useEffect(() => {
    !isExistOrderSeat && setOrdersSeat([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTicket])

  const handleChooseSeat = (e: any, seat: any, rows: any) => {
    const _this = e.currentTarget;
    if (!seat.seat_type) return
    handleAddWithTypeSeat(seat.seat_type, _this, seat, rows)
    // if (seat.seat_type == "ĐÔI") {
    //   // Chọn ghế đôi
    //   handleAddWithTypeSeat("ĐÔI", _this, seat, rows)
    // }
    // if (seat.seat_type == "ĐƠN") {
    //   // Chọn ghế đôi
    //   handleAddWithTypeSeat("ĐƠN", _this, seat, rows)
    // }
    // if (seat.seat_type == "NẰM") {
    //   // Chọn GIƯỜNG
    //   handleAddWithTypeSeat("NẰM", _this, seat, rows)
    // }
  }
  useEffect(() => {
    const listSeatVip: NodeListOf<HTMLElement> = document.querySelectorAll(".seat-table table tr .seat-td.seat-vip-row-1")
    //listSeatVip2 - like the listSeatVip but containing empty seat
    const listSeatVip2: NodeListOf<HTMLElement> = document.querySelectorAll(".seat-table table tr .seat-vip-row-1")
    // const lastRowVip:NodeListOf<HTMLElement>  = document.querySelectorAll(`.seat-table table tr .seat-td.seat-vip-row-${currentRow}`)
    // const lastRowVip2:NodeListOf<HTMLElement>  = document.querySelectorAll(`.seat-table table tr .seat-vip-row-${currentRow}`)
    listSeatVip && listSeatVip[0]?.classList.add("first-vip")
    listSeatVip && listSeatVip[listSeatVip.length - 1]?.classList.add("last-vip")
    // lastRowVip && lastRowVip[0]?.classList.add("first-vip")
    // lastRowVip && lastRowVip[lastRowVip.length - 1]?.classList.add("last-vip")
    const parentFirstVip = listSeatVip[0]?.parentElement?.querySelectorAll("td")
    if (parentFirstVip) {
      const indexFirstVip = Array.from(parentFirstVip)?.findIndex(el => el.classList.contains("first-vip"));
      const indexLastVip = Array.from(parentFirstVip)?.findIndex(el => el.classList.contains("last-vip"));
      for (let i = 2; i <= currentRow; i++) {
        const middleRowVip: HTMLElement | undefined | null = document.querySelector(`.seat-table table tr .seat-vip-row-${i}`)?.closest("tr")
        const childMiddleRow = middleRowVip?.querySelectorAll("td")
        if (childMiddleRow) {
          const countSeatCouple = Array.from(childMiddleRow).filter((el, index) => el.classList.contains("seat-couple") && index < indexFirstVip)
          const indexFirst = countSeatCouple.length > 0 ? indexFirstVip - countSeatCouple.length : indexFirstVip
          const indexLast = countSeatCouple.length > 0 ? indexLastVip - countSeatCouple.length : indexLastVip
          childMiddleRow[indexFirst]?.classList.add(i == currentRow ? "first-vip" : "seat-vip-row-middle-first")
          childMiddleRow[indexLast]?.classList.add(i == currentRow ? "last-vip" : "seat-vip-row-middle-last")
          i == currentRow && childMiddleRow.forEach((item, i) => {
            if (i <= indexLast && i >= indexFirst) {
              item.classList.contains(`seat-vip-row-${currentRow}`) && item.classList.add("seat-vip-row-last")
              if (!item.classList.contains("seat-td")) {
                item.classList.add("seat-special-bot")
              }
            }
          })
        }
      }
    }
    const indexFirstSeat = Array.from(listSeatVip2).findIndex(item => item.classList.contains("first-vip"));
    const indexLastSeat = Array.from(listSeatVip2).findIndex(item => item.classList.contains("last-vip"));
    listSeatVip2.forEach((item, i) => {
      item.classList.add("seat-vip-row-1")
      if (!item.classList.contains("seat-td") && i < indexLastSeat && i > indexFirstSeat) {
        item.classList.add("seat-special-top")
      }
    })
    // lastRowVip2.forEach((item, i)=>{
    //  item.classList.add("seat-vip-row-last")
    //   if(!item.classList.contains("seat-td") && i < indexLastSeat && i > indexFirstSeat){
    //     item.classList.add("seat-special-bot")
    //   }
    // })
  }, [listSeat, currentRow])
  const handleClosePopupNoti = () => {
    setNoti({ value: false, content: noti?.content });
  }
  const handleGetClass = (maxColumn: number) => {
    if (maxColumn <= 13) return "--sm"
    if (maxColumn <= 16) return "--nor"
    if (maxColumn < 21) return "--full"
    return "--full --large"
  }
  if ((!listSeat || listSeat?.length == 0) || !cinemaInterest) {
    return null
  }

  let maxColumn = listSeat?.[0]?.maxcolumn;
  let maxRow = listSeat?.length;

  let rowSeatCenter = Math.ceil(maxColumn * 0.4);
  let colSeatCenter = Math.ceil(maxRow * 0.4);


  if (maxColumn % 2 != 0 && rowSeatCenter % 2 == 0) {
    rowSeatCenter = rowSeatCenter - 1;
  }

  if (maxRow % 2 != 0 && colSeatCenter % 2 == 0) {
    colSeatCenter = colSeatCenter - 1;
  }
  const rowStart = Math.ceil((maxColumn - rowSeatCenter) / 2);
  const colStart = Math.ceil((maxRow - colSeatCenter) / 2);
  return (
    <Fragment>
      <PopupNoti isOpen={noti?.value} handleClose={handleClosePopupNoti} classCustom='--w7' contentTitle="LƯU Ý !" content={noti?.content} />
      <section className="sec-seat" >
        <div className="seat">
          <div className="container">
            <div className="seat-wr">
              <div className="seat-heading sec-heading" data-aos="fade-up">
                <h2 className="heading">{getValueByKey("select_Seats")} - {getValueByKey("Theater") ?? "Rạp"} {cinemaInterest?.room_name}</h2>
              </div>
              <IndicatorScroll>
                <div className={`seat-block relative ${handleGetClass(maxColumn)}`}>
                  {
                    isLoading && <LoadingCustom />
                  }
                  <div className="seat-screen" data-aos="fade-up"><img src="/assets/images/img-screen.png" alt="" />
                    <div className="txt">{getValueByKey("Screen")}</div>
                  </div>
                  <div className="seat-main" onTouchStart={HandleMapTouchStart} onTouchEnd={HandleMapTouchEnd} data-aos="fade-up">
                    <Minimap selector=".seat-td" width={130} keepAspectRatio={true}>
                      <div className="seat-table">
                        <table className='seat-table-inner' ref={refElementRef}>
                          {listSeat?.map((value: any, index: number) => {
                            const rowNAme = value.rowname;
                            const rowSeat = value.rowseats;
                            let isDifferentRow = false
                            return (
                              <tr key={index}>
                                <td className="seat-name-row">{rowNAme}</td>
                                {rowSeat.map((seat: any, i: number) => {
                                  const isChoosing = ordersSeat?.find((item: any) => item?.ticketid == seat?.ticketid)
                                  const isSeatVip = (i >= rowStart && i < (rowSeatCenter + rowStart)) && (index >= colStart && index < (colSeatCenter + colStart)) && cinemaInterest?.room_type_name_en == "Standard"
                                  if (isSeatVip) {
                                    currentRow = !isDifferentRow ? currentRow + 1 : currentRow
                                    isDifferentRow = true
                                  }
                                  if (seat.seat_type == "ĐÔI" && seat.price == 0) return <Fragment key={i}></Fragment>
                                  return <Fragment key={i}>
                                    {seat.seat_type == "ĐÔI" ?
                                      (
                                        <td key={i} className="seat-td seat-couple">
                                          <div className={seat.issold ? "seat-wr booked" : `seat-wr ${isChoosing ? "choosing" : ""}`} onClick={(e) => handleChooseSeat(e, seat, rowSeat)}>
                                            <img src="/assets/images/seat-couple-w.svg" alt="" />
                                            <span className="seat-name">{seat.name}</span>
                                          </div>
                                        </td>
                                      )
                                      :
                                      seat.colindex == i + 1 && seat.isseat && !seat.isoff ?
                                        (

                                          <td key={seat.ticketid} className={isSeatVip ? `seat-td seat-vip seat-vip-row-${currentRow}` : "seat-td"}>
                                            <div className={seat.issold ? "seat-wr seat-single booked" : `seat-wr seat-single ${isChoosing ? "choosing" : ""}`} onClick={(e) => handleChooseSeat(e, seat, rowSeat)}>
                                              {
                                                isSeatVip ?
                                                  // <img src="/assets/images/seat-vip.svg" alt=""/>
                                                  <img src="/assets/images/seat.svg" alt="" />
                                                  :
                                                  // <img src="/assets/images/seat-single.svg" alt="" />
                                                  <img src="/assets/images/seat.svg" alt="" />
                                              }
                                              <span className="seat-name">{seat.name}</span>
                                            </div>
                                          </td>
                                        ) : (
                                          <td key={cinemaInterest?.showtime_id} className={(i >= rowStart && i < (rowSeatCenter + rowStart)) && (index >= colStart && index < (colSeatCenter + colStart)) ? `seat-vip seat-vip-row-${currentRow}` : ""} />
                                        )}
                                  </Fragment>
                                })
                                }
                              </tr>
                            )
                          })
                          }
                        </table>
                      </div>
                    </Minimap>
                  </div>
                </div>
              </IndicatorScroll>
              <ul className="seat-note">
                {cinemaInterest?.room_type_name_en == "Standard" || cinemaInterest?.room_type_name_vn == "Standard" ?
                  <>
                    <li className="note-it">
                      <div className="image"> <img src="/assets/images/seat.svg" alt="" />
                      </div><span className="txt">{getValueByKey("Seat_Normally")}</span>
                    </li>
                    {listSeat?.find((item: any) => item.rowseats?.find((seat: any) => seat.seat_type == 'ĐÔI')) && (
                      <li className="note-it note-it-couple">
                        <div className="image">
                          <img src="/assets/images/seat-couple-w.svg" alt="" />
                        </div>
                        <span className="txt">{getValueByKey("Seat_Double")} (2 {getValueByKey("People") ?? "người"})</span>
                      </li>
                    )}
                    {/* <li className="note-it">
                      <div className="image"> <img src="/assets/images/seat-vip.svg" alt="" />
                      </div><span className="txt">{getValueByKey("Seat_VIP")}</span>
                    </li> */}
                  </> :
                  <>
                    <li className="note-it">
                      <div className="image"> <img src="/assets/images/seat.svg" alt="" />
                      </div><span className="txt">{getValueByKey("Bed")} (1 {getValueByKey("Person") ?? "người"})</span>
                    </li>
                  </>
                }

                {/* <li className="note-it">
                <div className="image"> <img src="/assets/images/seat-vip.svg" alt="" />
                </div><span className="txt">Ghế Vip</span>
              </li> */}
                <li className="note-it">
                  <div className="image">
                    {/* <img src="/assets/images/seat-single-selecting.svg" alt="" /> */}
                    <img src="/assets/images/seat.svg" alt="" className='seat-choosing' />
                  </div><span className="txt">{getValueByKey("Seat_Selected")}</span>
                </li>
                <li className="note-it">
                  <div className="image"> <img src="/assets/images/seat.svg" alt="" className='seat-disable' />
                  </div><span className="txt">{getValueByKey("Seat_Booked")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}